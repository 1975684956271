import { getStrapiURL } from '@utils/strapi'
import delve from 'dlv'

const globalAdapter = (json: object) => {
  const contact = delve(json, 'contact')
  contact.social_networks = (delve(contact, 'social_networks.data') ?? []).map((s: object) =>
    delve(s, 'attributes'),
  )
  const footer = delve(json, 'footer')
  footer.social_networks = (delve(footer, 'social_networks.data') ?? []).map((s: object) =>
    delve(s, 'attributes'),
  )
  const contactColumn = delve(footer, 'contact')
  contactColumn.social_networks = (delve(contactColumn, 'social_networks.data') ?? []).map(
    (s: object) => delve(s, 'attributes'),
  )
  footer.contact = contactColumn
  return { ...json, contact, footer }
}

const fetchGlobal = async (locale: string) => {
  const res = await fetch(getStrapiURL(`/global?populate=deep&locale=${locale}`), {
    headers: {
      Authorization: `Bearer ${process.env.STRAPI_API_TOKEN}`,
    },
  })
  const json = await res.json()
  return json.data ? globalAdapter(delve(json.data, 'attributes')) : null
}

export default fetchGlobal
