import '@styles/globals.scss'
import '@fortawesome/fontawesome-svg-core/styles.css'

import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core'
import fetchGlobal from '@utils/fetchGlobal'
import useWindowDimensions from '@utils/hooks/useWindowDimensions'
import { getLocalizedParams } from '@utils/i18n/localize'
import App, { AppContext, AppProps } from 'next/app'
import ErrorPage from 'next/error'
import Head from 'next/head'
import Script from 'next/script'
import absoluteUrl from 'next-absolute-url'
import { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

// Prevent fontAwesome from adding CSS itself
fontAwesomeConfig.autoAddCss = false

const queryClient = new QueryClient()

function TheApp({ Component, pageProps }: AppProps) {
  const windowDimensions = useWindowDimensions()

  useEffect(() => {
    // NOTE: This is a workaround for crowdiness on this specific resolution
    if (windowDimensions.width !== undefined && windowDimensions.width < 1536)
      document.documentElement.style.fontSize = '14px'
  }, [windowDimensions])

  const { global } = pageProps
  if (global === null) {
    return <ErrorPage statusCode={404} />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <meta
          name="viewport"
          content="initial-scale=1, maximum-scale=1,width=device-width, user-scalable=no, viewport-fit=cover"
        />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      </Head>
      {process.env.NODE_ENV !== 'test' && (
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-W473ZDH');`}
        </Script>
      )}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...pageProps} />
    </QueryClientProvider>
  )
}

TheApp.getInitialProps = async (appContext: AppContext) => {
  const { locale } = getLocalizedParams(appContext.ctx.query)
  const { origin } = absoluteUrl(appContext.ctx.req)

  const appProps = await App.getInitialProps(appContext)

  try {
    let global = await fetchGlobal(locale)
    if (!global) {
      if (process.env.NEXT_PUBLIC_LOCALE_FALLBACK)
        global = await fetchGlobal(process.env.NEXT_PUBLIC_LOCALE_FALLBACK)
      else throw new Error('No global found for locale')
    }

    return { ...appProps, pageProps: { globalData: global, origin } }
  } catch (error) {
    return { ...appProps, pageProps: { origin } }
  }
}

export default TheApp
